<template>
  <div
    id="stats-wrapper"
    elevation="10"
    class="py-4 px-4"
    style="background-color: white; max-height: 800px; overflow-y: scroll;"
  > 
    <h2 
      class="py-2 px-2 background-element-primary"
      style="border-radius: 5px; color: white;"
    >
      Estadísticas Generales
    </h2>
    <v-row 
      v-show="!loading"
      justify="center"
      align="center"
      class="my-4"
    >
      <v-col
        class="px-8"
        cols="12"
        md="4"
      >
        <v-layout row wrap>
          <v-menu
            v-model="fromDateMenu"
            :close-on-content-click="false"
            :nude-rigth="40"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template
              v-slot:activator="{ on }"
            >
              <v-text-field
                v-model="desdeFecha"
                label="Desde"
                prepend-icon="mdi-calendar"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="fromDate"
              locale="en-in"
              no-title
              @input="fromDateMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-layout>
      </v-col>

      <v-col
        class="px-8"
        cols="12"
        md="4"
      >
        <v-layout row wrap>
          <v-menu
            v-model="toDateMenu"
            :close-on-content-click="false"
            :nude-rigth="40"
            transition="scale-transition"
            offset-y
            max-width="290px"
            min-width="290px"
          >
            <template
              v-slot:activator="{ on }"
            >
              <v-text-field
                v-model="hastaFecha"
                label="Hasta"
                prepend-icon="mdi-calendar"
                readonly
                v-on="on"
              ></v-text-field>
            </template>
            <v-date-picker
              v-model="toDate"
              locale="en-in"
              no-title
              @input="toDateMenu = false"
            ></v-date-picker>
          </v-menu>
        </v-layout>
      </v-col>

      <v-col
        cols="12"
        md="2"
      >
        <v-btn
          color="primary"
          @click="getVentas"
        >
          Buscar
        </v-btn>
      </v-col>
    </v-row>

    <v-divider></v-divider>

    <v-row 
      v-show="!loading"
      justify="center"
      align="center"
      class="my-4"
    >
      <v-col
        cols="12"
        md="4"
        height="auto"
      >
        <v-card
          class="mx-auto my-2"
          max-width="300"
          height="200"
        >
          <v-card-title
            class="background-element-secondary px-2 py-2 text-center"
          >
            <div
              style="color: white; width: 100%;"
            >
              Ventas en el rango de fecha
            </div>
          </v-card-title>
          <v-card-text
            class="text-center d-flex"
            style="height: 150px"
          >
            <p
              class="my-auto mx-auto"
              style="font-size: 2rem; color: #FF3700"
            >
              {{ ventasMes | currency | commaThousand }}
            </p>
          </v-card-text>
        </v-card>
      </v-col>

      <v-col
        cols="12"
        md="4"
        height="auto"
      >
        <v-card
          class="mx-auto my-2"
          width="320"
          max-width="320"
        >

          <v-card-title
            class="background-element-secondary px-2 py-2 text-center"
            style="height: 50px;"
          >
            <div
              style="color: white; width: 100%;"
            >
              Total Ordenes Rango de Fecha:
            </div>
          </v-card-title>
          <v-card-text
            class="text-center d-flex"
            style="height: 150px"
          >
            <p
              class="my-auto mx-auto"
              style="font-size: 2rem; color: #FF3700"
            >
              {{ numOrdenesRangoFecha }}
            </p>
          </v-card-text>
        </v-card>
      </v-col>
    </v-row>

    <v-row
      class="my-4"
      v-show="!loading"
      justify="center"
      align="center"
    >
      <v-col
        v-for="tableData in tablesData"
        :key="tableData.id"
        cols="12"
        md="6"
      >
        <dashboard-table
          :tableData="tableData"
        ></dashboard-table>
      </v-col>
    </v-row>
    <v-divider class="my-4"></v-divider>

    <v-row
      v-show="!loading"
      justify="center"
      align="center"
    >
      <!-- TODO: hacer un for de las cols de las gráficas -->
      <v-col
        cols="12"
        md="12"
      >
        <!-- <v-card>
          <v-card-title
            class="background-element-secondary px-2 py-2 text-center"
          >
            <div
              style="color: white; width: 100%;"
            >
              VENTAS POR MES
            </div>
          </v-card-title>
          <v-card-text> -->
            <ventas-mes-graph
              ref="ventasMesActual"
            ></ventas-mes-graph>
          <!-- </v-card-text>
        </v-card> -->
      </v-col>
      <v-col
        cols="12"
        md="12"
      >
        <!-- <v-card
          height="400px"
        >
          <v-card-title
            class="background-element-secondary px-2 py-2 text-center"
          >
            <div
              style="color: white; width: 100%;"
            >
              VENTAS POR CATEGORIA 
            </div>
          </v-card-title>
          <v-card-text> -->
            <ventas-mes-categoria-graph
              ref="ventasMesCategoria"
            ></ventas-mes-categoria-graph>
          <!-- </v-card-text>
        </v-card> -->
      </v-col>
    </v-row>

    <component-loader
      v-if="loading"
    ></component-loader>
  </div>
</template>

<script>
import { ApiAuth } from '@/api_utils/axios-base'
import { tFilters } from '@/mixins/filters.js'
import componentLoader from '@/components/ComponentLoader'
import { mapState } from 'vuex'

export default {
  metaInfo: {
    title: 'Estadísticas generales - Dashboard'
  },
  mixins: [tFilters],
  data() {
    return {
      fromDate: new Date().toISOString().substr(0,10),
      toDate: new Date().toISOString().substr(0,10),
      fromDateMenu: false,
      toDateMenu: false,
      loading: false,
      ventasMes: 0,
      numOrdenesRangoFecha: 0,
      todasOrdenes: [],
      HHSSInicio: "T00:00",
      HHSSFin: "T23:59",
      tablesData: [
        {
          id: 1,
          tableName: "Órdenes que necesitan atención",
          headers: [
            { text: 'Número orden', value:'', sortable: false },
            { text: 'Cliente', value:'', sortable: false },
            { text: 'Estatus', value:'', sortable: false },
          ],
          items: []
        },
        {
          id: 2,
          tableName: "Productos más vendidos",
          headers: [
            { text: 'Nombre', value:'nombre_producto', sortable: false },
            { text: 'Ventas lapso de fecha', value:'ventas_totales', sortable: false },
          ],
          items: []
        },
        {
          id: 3,
          tableName: "Semanas Inventario",
          headers: [
            { text: 'Nombre', value:'nombre_producto', sortable: false },
            { text: 'Ventas promedio/semana', value:'promedio_unidades_vendidas', sortable: false },
            { text: 'Inventario actual', value:'inventario', sortable: false },
            { text: 'Semanas inventario', value:'semanas_inventario', sortable: false },
          ],
          items: []
        },
        {
          id: 4,
          tableName: "Clientes Top",
          headers: [
            { text: 'Cliente', value:'nombre_cliente', sortable: false },
            { text: 'Frecuencia', value:'frecuencia', sortable: false },
            { text: 'Total de compras', value:'total_compras', sortable: false },
          ],
          items: []
        }
      ],
    }
  },
  components: {
    ventasMesGraph: () => import('../Graphs/VentasMesActual.vue'),
    ventasMesCategoriaGraph: () => import('../Graphs/VentasMesCategoria.vue'),
    dashboardTable: () => import('@/components/utils/Tables/DashboardTable'),
    componentLoader,
  },
  computed: {
    ...mapState('products', ['categories']),
    ...mapState('usuario', ['userData']),
    desdeFecha() {
      return this.fromDate
    },
    hastaFecha() {
      return this.toDate
    },
    distributorId() {
      return this.userData.tipo_usuario === "Distribuidor" ? this.userData.distribuidorId : ""
    },
  },
  methods: {
    getVentas() {
      if (this.userData.tipo_usuario === "Distribuidor") {
        this.ventasDelMesDistribuidor()
      } else {
        this.ventasDelMes()
      }
    },
    async ventasDelMes() {
      try {
        let response = await ApiAuth.post('/reportes/total-ventas/',
          { 
            fecha_inicio: this.desdeFecha + this.HHSSInicio, 
            fecha_fin: this.hastaFecha + this.HHSSFin
          }
        )
        let ventas = await response.data.total_ventas|| 0

        this.ventasMes = ventas
        this.productosMasVendidos()
        this.semanasInventario()
        this.totalOrdenesRangoFecha()
      } catch(error) {
        console.error("error getting sales", error)
      }
    },
    async ventasDelMesDistribuidor() {
      try {
        let response = await ApiAuth.post('/reportes/ventas-por-distribuidor/',
          { 
            fecha_inicio: this.desdeFecha + this.HHSSInicio, 
            fecha_fin: this.hastaFecha + this.HHSSFin,
            id_distribuidor: this.distributorId
          }
        )
        let ventas = await response.data.total_ventas || 0

        this.ventasMes = ventas
        this.productosMasVendidosDistribuidor()
        this.semanasInventarioAlmacen()
        this.totalOrdenesRangoFechaDistribuidor()
      } catch(error) {
        console.error("error getting sales distribuidor", error)
      }
    },
    async allOrders() {
      try{
        this.loading = true
        let response = await ApiAuth.get('/checkout/api/ordenes/')
        let ordenes = await Object.freeze(response.data)

        let ventas = await ApiAuth.post('/reportes/ventas-por-categoria/', 
          {
	          fecha_inicio: "2021-01-01",
            fecha_fin: new Date().toISOString().substring(0, 10)
          })
        let ventasPorCategoria = await Object.freeze(ventas.data)

        this.todasOrdenes = ordenes
        this.$refs.ventasMesActual.ordenesPorMes(ordenes)
        this.$refs.ventasMesCategoria.createDatasets(ventasPorCategoria, this.categories)
        this.loading = false 
      } catch(error) {
        console.error("error getting orders", error)
      }
    },
    async allOrdersDistribuidor() {
      try{
        this.loading = true
        let response = await ApiAuth.get(`/reportes/api/ordenes-por-distribuidor/?id_distribuidor=${this.distributorId}`)
        let ordenes = await Object.freeze(response.data)

        let ventas = await ApiAuth.post('/reportes/ventas-por-categoria-distribuidor/', 
          {
	          fecha_inicio: "2021-01-01",
            fecha_fin: new Date().toISOString().substring(0, 10),
            id_distribuidor: this.distributorId
          })
        let ventasPorCategoria = await Object.freeze(ventas.data)

        this.todasOrdenes = ordenes
        this.$refs.ventasMesActual.ordenesPorMes(ordenes)
        this.$refs.ventasMesCategoria.createDatasets(ventasPorCategoria, this.categories)
        this.loading = false 
      } catch(error) {
        console.error("error getting orders distribuidor", error)
      }
    },
    async productosMasVendidos() {
      try {
        let response = await ApiAuth.post('/reportes/productos-mas-vendidos/',
          {
            fecha_inicio: this.desdeFecha + this.HHSSInicio,
            fecha_fin: this.hastaFecha + this.HHSSFin 
          }
        )
        let masVendidos = await Object.freeze(response.data)
        this.tablesData[1].items =  masVendidos
      } catch(error) {
        console.error("error getting products", error)
      }
    },
    async productosMasVendidosDistribuidor() {
      try {
        let response = await ApiAuth.post('/reportes/productos-mas-vendidos-por-distribuidor/',
          {
            fecha_inicio: this.desdeFecha + this.HHSSInicio,
            fecha_fin: this.hastaFecha + this.HHSSFin,
            id_distribuidor: this.distributorId.toString()
          }
        )
        let masVendidos = await Object.freeze(response.data)
        this.tablesData[1].items =  masVendidos
      } catch(error) {
        console.error("error getting products distribuidor", error)
      }
    },
    async semanasInventario() {
      try {
        let response = await ApiAuth.post('/reportes/semanas-inventario/',
          {
            fecha_inicio: this.desdeFecha,
            fecha_fin: this.hastaFecha
          }
        )

        let invSemana = await Object.freeze(response.data)
        this.tablesData[2].items = invSemana
      } catch(error) {
        console.log("error getting invSemana", error)
      }
    },
    async semanasInventarioAlmacen() {
      try {
        let response = await ApiAuth.post('/reportes/semanas-inventario-almacen/',
          {
            fecha_inicio: this.desdeFecha,
            fecha_fin: this.hastaFecha,
            id_almacen: this.userData.almacenId
          }
        )

        let invSemana = await Object.freeze(response.data)
        this.tablesData[2].items = invSemana
      } catch(error) {
        console.log("error getting invSemana almacen", error)
      }
    },
    async totalOrdenesRangoFecha() {
      try{
        let response = await ApiAuth.post('/reportes/ordenes-rango-fecha/',
          {
            fecha_inicio: this.desdeFecha + this.HHSSInicio,
            fecha_fin: this.hastaFecha + this.HHSSFin
          }
        )
        let totalOrdenes = await response.data[0].ordenes_en_rango
        
        this.numOrdenesRangoFecha = totalOrdenes
      } catch(error) {
        console.error("error getting ordenes rango fecha", error)
      }
    },
    async totalOrdenesRangoFechaDistribuidor() {
      try{
        let response = await ApiAuth.post('/reportes/ordenes-rango-fecha-distribuidor/',
          {
            fecha_inicio: this.desdeFecha + this.HHSSInicio,
            fecha_fin: this.hastaFecha + this.HHSSFin,
            id_distribuidor: this.distributorId
          }
        )
        let totalOrdenes = await response.data[0].ordenes_en_rango
        
        this.numOrdenesRangoFecha = totalOrdenes
      } catch(error) {
        console.error("error getting ordenes rango fecha distribuidor", error)
      }
    },
    async clientesTop() {
      try {
        let response = await ApiAuth.get('/reportes/clientes-top/')
        let clientesTop = Object.freeze(response.data)

        this.tablesData[3].items = clientesTop
      } catch(error) {
        console.error("error getting clientes top", error)
      }
    },
    async clientesTopDistribuidor() {
      try {
        // console.log("distributorId: ", this.distributorId);
        let response = await ApiAuth.post('/reportes/clientes-top-distribuidor/',
          { id_distribuidor: this.distributorId })
        let clientesTop = Object.freeze(response.data)

        this.tablesData[3].items = clientesTop
      } catch(error) {
        console.error("error getting clientes top distribuidor", error)
      }
    },
  },
  created() {
    // console.log("Tipo usuario: ", this.userData.tipo_usuario);
    if (this.userData.tipo_usuario === "Distribuidor") {
      this.clientesTopDistribuidor()
    } else {
      this.clientesTop()
    }
  },
  mounted() {
    if (this.userData.tipo_usuario === "Distribuidor") {
      this.allOrdersDistribuidor()
    } else {
      this.allOrders()
    }
  },
}
</script>

<style scoped>
#stats-wrapper::-webkit-scrollbar {
  width: 10px;
}
#stats-wrapper::-webkit-scrollbar-track {
  background: white;
}
#stats-wrapper::-webkit-scrollbar-thumb {
  background-color: #FF3700;
  border-radius: 20px;
  border: 1px solid white;
}
</style>